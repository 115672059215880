<template>
  <header class="app-header">
    <div class="header-left">
      <MainLogo />
      <div class="social-btns">
        <base-button
          class="social-btn discord-btn"
          :icon="'fa-brands fa-discord'"
          @click="openDiscordLink"
          :icon-position="'left'"
          :when-to-shrink="0"
        >
        </base-button>
        <base-button
          class="social-btn reddit-btn"
          :icon="'fa-brands fa-reddit'"
          @click="openRedditLink"
          :icon-position="'left'"
          :when-to-shrink="0"
        />
        <base-button
          class="social-btn twitter-btn"
          :icon="'fa-brands fa-x-twitter'"
          @click="openTwitterLink"
          :icon-position="'left'"
          :when-to-shrink="0"
        >
        </base-button>
      </div>
    </div>
    <!-- <p v-if="!userStore.allReady">
      Tot AIs created: <strong>{{ this.numAIgenerated }}</strong>
    </p> #TODO: disabled until you understand the impliction for timing-->
    <profile-screen
      v-if="showUserProfile && userStore.allReady"
      @close="showUserProfile = false"
    >
    </profile-screen>

    <div class="header-right" v-if="userStore.allReady">
      <div class="time-left text-small" v-if="timerAlwaysOn">
        Time Left:
        <span class="tot-seconds-left text-small">{{ checkTimeLeft() }}</span>
      </div>
      <base-button
        class="head-right-btn friends-btn"
        id="friends-btn"
        v-if="!userStore.isAnonymous"
        @click="
          showFriendsPanel = true
          //   hideSpeechBubble();
        "
        ><img class="people-icon" src="@/assets/g6.svg" alt="Custom Icon"
      /></base-button>

      <base-button
        v-if="!userStore.isAnonymous"
        @click="showUserProfile = true"
        class="head-right-btn profile-btn"
        :icon="'fas fa-user'"
        :icon-position="'left'"
        :when-to-shrink="timerAlwaysOn ? 450 : 250"
      >
      </base-button>
      <base-button
        v-if="userStore.isAnonymous"
        @click="$emit('show-sign-in')"
        :icon="'far fa-user'"
        class="sign-in-btn"
        :icon-position="'left'"
        :when-to-shrink="timerAlwaysOn ? 450 : 250"
        >&nbsp;&nbsp;Sign Up
      </base-button>
      <panel-friends
        v-if="showFriendsPanel"
        @close="showFriendsPanel = false"
      ></panel-friends>

      <div class="settings">
        <button @click="toggleSettings" class="settings-burger-button">
          <i class="fa-solid fa-bars"></i>
        </button>
        <div v-if="showSettings" class="settings-menu">
          <div class="setting-el">
            <input
              type="checkbox"
              id="checkbox-id"
              v-model="timerAlwaysOn"
              @change="updateTimer"
            />
            <label for="checkbox-id">Timer Always On</label>
          </div>
          <div class="setting-el">
            <base-button :small-button="true" @click="toggleDarkMode"
              >Toggle Dark Mode</base-button
            >
          </div>
          <div class="setting-el volume-control">
            <i
              class="fa-solid fa-volume-up"
              v-if="userStore.volumePerc > 0"
            ></i>
            <i class="fa-solid fa-volume-mute" v-else></i>
            <input
              type="range"
              id="volume-control"
              min="0"
              max="100"
              v-model="userStore.volumePerc"
              @input="updateVolume"
            />
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
// import { hideSpeechBubble } from "@/services/userService";

import PanelFriends from "./PanelFriends.vue";
import BaseButton from "./BaseButton.vue";
import ProfileScreen from "./ProfileScreen.vue";
import { useAggregatesStore } from "@/store/aggregates";
import { useUserStore } from "@/store/userStore";
import { fromSecs } from "@/services/userService";
import MainLogo from "./MainLogo.vue";
import {
  enable as enableDarkMode,
  disable as disableDarkMode,
} from "darkreader";
export default {
  components: {
    BaseButton,
    ProfileScreen,
    PanelFriends,
    MainLogo,
  },
  data() {
    return {
      darkModeEnabled: false,
      userStore: useUserStore(),
      numAIgenerated: 0,
      aggStore: useAggregatesStore(),
      showSettings: false,
      showUserProfile: false,
      timerAlwaysOn: false,
      showFriendsPanel: false,
      unsubscribe: null,
      showPreferences: false,
      //   showForm: false,
      // volumePerc: 0
    };
  },
  mounted() {
    this.fetchNumAIgenerated();
    this.userStore.volumePerc = this.getLocalStorageItem("userVolumePerc", 50);
    if (this.userStore.locPaidSecsLeft == 0) {
      this.timerAlwaysOn = false;
    } else {
      this.timerAlwaysOn = this.getLocalStorageItem("timerAlwaysOn", false);
    }
    this.checkHash(); // Check the hash when the component is mounted
    window.addEventListener("hashchange", this.checkHash);
  },
  beforeDestroy() {
    // Clean up the event listener
    window.removeEventListener("hashchange", this.checkHash);
  },
  methods: {
    toggleDarkMode() {
      if (this.darkModeEnabled) {
        disableDarkMode();
        localStorage.setItem("darkMode", "false");
      } else {
        enableDarkMode({
          brightness: 100,
          contrast: 90,
          sepia: 10,
        });
        localStorage.setItem("darkMode", "true");
      }
      this.darkModeEnabled = !this.darkModeEnabled;
    },
    checkTimeLeft() {
      if (this.userStore.isSubscribed) {
        return "+ inf!";
      }
      if (this.userStore.totSecondsLeft < 60) {
        return "< 1 min!";
      } else {
        return "~" + Math.floor(this.userStore.totSecondsLeft / 60) + " mins";
      }
    },
    checkHash() {
      const hash = window.location.hash;
      if (hash.startsWith("#/success")) {
        this.showUserProfile = true;
        window.history.replaceState(null, null, window.location.pathname);
      }
    },
    fetchNumAIgenerated() {
      this.aggStore.fetchNumAIgenerated().then(() => {
        this.numAIgenerated = this.aggStore.numAIgenerated;
      });
    },
    openDiscordLink(event) {
      event.stopPropagation();
      window.open(
        "https://discord.gg/6kg5XMYtjS",
        "_blank",
        "noopener,noreferrer"
      );
    },
    openTwitterLink(event) {
      event.stopPropagation();
      window.open("https://x.com/theaipeeps", "_blank", "noopener,noreferrer");
    },
    openRedditLink(event) {
      event.stopPropagation();
      window.open(
        "https://www.reddit.com/r/theAIpeeps/",
        "_blank",
        "noopener,noreferrer"
      );
    },

    getLocalStorageItem(key, defaultValue) {
      const storedValue = JSON.parse(localStorage.getItem(key));
      if (storedValue !== null) {
        return JSON.parse(storedValue); // Assuming the stored value is a JSON string
      }
      return defaultValue;
    },
    updateTimer() {
      localStorage.setItem("timerAlwaysOn", JSON.stringify(this.timerAlwaysOn));
    },
    fromSecs,
    toggleSettings() {
      this.showSettings = !this.showSettings;
    },
    updateVolume() {
      localStorage.setItem("userVolumePerc", this.userStore.volumePerc);
    },
  },
};
</script>

<style scoped>
.app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px;
  padding-top: 25px;
  background-color: #f5f5f5;
  max-height: 10dvh;
  /* min-height: 60px; with this some control components will be cut because of lack of space. Think better if you wanna change it*/
  width: 100dvw;
  overflow: hidden;
}

.header-left {
  flex: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: clamp(2px, 3dvw, 50px);
}

.header-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: clamp(2px, 1.5dvw, 50px);
}

.settings-container {
  display: flex;
  flex-direction: column;
}

.settings-burger-button {
  flex-shrink: 0;
  border: none;
  background: none;
  cursor: pointer;
}

.profile-btn ::v-deep .base-button {
  display: flex;
  justify-content: center;
  font-size: 50px !important;
}

.head-right-btn ::v-deep .base-button {
  width: 50px;
  height: 50px;
  padding: 10px;
  border: 2px solid;
  border-radius: 50%;
}

.profile-btn ::v-deep i {
  font-size: 22px;
}

.setting-el {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.settings-menu {
  position: absolute;
  top: clamp(50px, 12dvh, 100px);
  right: 20px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 10px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 10px;
  z-index: 3;
}

#checkbox-id {
  width: 20px;
}
#volume-control {
  width: 100px;
}
.people-icon {
  object-fit: contain;
  display: block;
  width: 40px;
}
.sign-in-btn ::v-deep .base-button {
  border: clamp(1px, 0.5dvh, 3px) solid;
  font-weight: bold;
  /* color: var(--background-color); */
  padding-right: clamp(0rem, 1.5dvw, 5rem);
  padding-left: clamp(0rem, 1.5dvw, 5rem);
  margin-right: 1dvh;
  display: flex;
  align-items: center;
}

.social-btn ::v-deep .base-button {
  width: 35px;
  /* max-width: 35px; */
  height: 35px;
  border-radius: 50%;
  color: white;
  font-weight: bold;
  font-size: clamp(0.8rem, 0.8dvw, 1rem);
  padding: 10px;
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out,
    color 0.3s ease-in-out, box-shadow 0.3s ease;
}

.discord-btn ::v-deep .base-button {
  background-color: var(--discord-blue);
}

.twitter-btn ::v-deep .base-button {
  background-color: black;
}
.reddit-btn ::v-deep .base-button {
  background-color: #ff4500;
}

.social-btn:hover ::v-deep .base-button,
.social-btn:focus ::v-deep .base-button {
  transform: scale(1.5);
  color: #ffffff;
  text-decoration: none;
}

.discord-btn:hover ::v-deep .base-button,
.discord-btn:focus ::v-deep .base-button {
  background-color: #f5f5f5;
  color: var(--discord-blue);
}

.twitter-btn:hover ::v-deep .base-button,
.twitter-btn:focus ::v-deep .base-button {
  background-color: #f5f5f5;
  color: black;
}

.reddit-btn:hover ::v-deep .base-button,
.reddit-btn:focus ::v-deep .base-button {
  background-color: #f5f5f5;
  color: #ff4500;
}

.discord-href {
  text-decoration: none;
  color: white;
}
.social-btns {
  display: flex;
  gap: clamp(2px, 1dvw, 50px);
}

@media (max-height: 650px) {
  .head-right-btn ::v-deep .base-button {
    width: 30px;
    height: 30px;
  }
  .people-icon {
    width: 25px;
  }
  .profile-btn ::v-deep i {
    font-size: 15px;
  }
}

@media (max-width: 600px) {
  .social-btn ::v-deep .base-button {
    padding-right: clamp(0rem, 2.5dvw, 5rem);
    padding-left: clamp(0rem, 2.5dvw, 5rem);
    width: 30px;
    height: 30px;
  }
}
</style>
