<template>
  <panel-message @close="$emit('close')" :close-on-click-out="true">
    <div class="select-and-text">
      <slot></slot>
      <div>
        <div
          class="text-mid-big title-not-available"
          style="font-weight: bold"
          v-html="mainTitle"
        ></div>
        <div class="arrow-container" style="color: red">
          <i class="text-arrow fas fa-arrow-down arrow-1"></i>
          <i class="text-arrow fas fa-arrow-down arrow-2"></i>
          <i class="text-arrow fas fa-arrow-down arrow-3"></i>
        </div>
        <!-- <div class="text-mid-big">⬇️⬇️⬇️⬇️⬇️</div> -->
      </div>
      <div class="paying-char-container">
        <div class="sub-container buymin-cont" v-if="showBuyCategory">
          <div class="sub-cont-title text-mid-big" v-html="unlockTitle"></div>
          <div>
            <strong v-html="mainText" />

            <div style="color: red; font-weight: bold">(valid forever)</div>
            <div class="text-mid-big"><strong>+</strong></div>
            <div class="text-mid-big free-time">
              <strong
                >{{ options_unlock_this.included_mins }} mins of chatting
                time!</strong
              >
            </div>
          </div>
          <div class="buymin-btns">
            <base-button
              class="spend onebtn"
              :action="
                () =>
                  redirectToCheckout({
                    type: 'unlock_options',
                    id: unlock_option_name.split('/')[0],
                  })
              "
            >
              <span class="emoji"></span>
              <div style="display: flex; flex-direction: column; gap: 2px">
                <span class="paid-seconds-left text-mid-big"
                  >${{ options_unlock_this.price }}</span
                >
                <span class="buy-text">One Time Payment</span>
              </div>
            </base-button>
          </div>
        </div>
        <strong class="text-mid-big" v-if="showBuyCategory">OR</strong>
        <div class="sub-container buy-all-opts" v-if="showBuyUnlockAll">
          <div
            class="sub-cont-title text-mid-big"
            style="color: rgb(214 65 194)"
          >
            🔓 Unlock Everything! 🔑
          </div>
          <div>
            <strong
              >Unlock all character customization options with a single
              purchase!</strong
            >
            <div style="color: red; font-weight: bold">(valid forever)</div>
          </div>
          <div>
            <ul
              style="
                text-align: left;
                padding-inline-end: 0px;
                margin-block-end: 0px;
                margin-block-start: 0px;
                max-width: 400px;
              "
            >
              <li>Select any country 🌎</li>
              <li>Select any age 👧</li>
              <li>Fully customize personality 🧠</li>
              <li>
                Choose from available hobbies, or <br />create your own 🧗🚴🚣
              </li>
              <li>
                Full control over sexy details 💦👄<br />including creating new
                kinks 👠
              </li>

              <!-- <li>Free access to future parameters not yet available! 💥</li> -->
            </ul>
            <div class="text-mid-big"><strong>+</strong></div>

            <div class="text-mid-big text-unlock">
              <strong
                ><span class="emoji-unlock">💥</span>
                Unlock Custom Instructions
                <span class="emoji-unlock">💥</span></strong
              >
            </div>

            <div class="text-mid-big"><strong>+</strong></div>
            <div class="text-mid-big free-time">
              <strong>60 mins of chatting time!</strong>
            </div>
          </div>
          <div class="buymin-btns">
            <base-button
              class="spend onebtn"
              :action="
                () =>
                  redirectToCheckout({
                    type: 'unlock_options',
                    id: 'unlock_all_options',
                  })
              "
            >
              <div style="display: flex; flex-direction: column; gap: 2px">
                <span style="text-decoration: line-through"
                  >${{ totIndividualPrices }}</span
                >
                <span class="paid-seconds-left text-mid-big"
                  >${{ options_unlock_all.price }}
                </span>

                <span class="buy-text">One Time Payment</span>
              </div>
            </base-button>
          </div>
        </div>
        <strong class="text-mid-big" v-if="showBuyUnlockAll">OR</strong>

        <subscription-container />
      </div>
      <p>
        <i class="text-very-small">
          All payments are securely processed through
          <strong
            ><a
              href="https://stripe.com"
              target="_blank"
              rel="noopener noreferrer"
              >Stripe</a
            ></strong
          >, a trusted and industry-leading payment provider</i
        >
      </p>
    </div>
  </panel-message>
</template>

<script>
import SubscriptionContainer from "@/components/paying_components/SubscriptionContainer.vue";
import PanelMessage from "@/components/PanelMessage.vue";
import BaseButton from "@/components/BaseButton.vue";
import { useSharedInfoStore } from "@/store/sharedInfoStore";
import { redirectToCheckout } from "@/services/userService.js";
import { computed } from "vue";
export default {
  components: {
    BaseButton,
    PanelMessage,
    SubscriptionContainer,
  },
  props: {
    option_to_buy: { type: String, required: true },
  },

  setup(props) {
    let optionToBuy = props.option_to_buy;
    let mainTitle = "";
    let unlockTitle = "";
    let mainText = "";
    let name_payment_option = "";
    let showBuyCategory = false;
    let showBuyUnlockAll = false;
    switch (optionToBuy) {
      case "general/country":
        showBuyCategory = true;
        showBuyUnlockAll = true;
        name_payment_option = "general";
        mainTitle = "Country Selection Is Not Available!";
        unlockTitle = "🌎 Unlock Country & Age Selection 🧓";
        mainText =
          "Choose ANY country you want your companion to be from + <br>Select an age from 18 to 90 years old!";
        break;
      case "general/age":
        showBuyCategory = true;
        showBuyUnlockAll = true;

        name_payment_option = "general";
        mainTitle = "Selected Age Unavailable!";
        unlockTitle = "🌎 Unlock Country & Age Selection 🧓";
        mainText =
          "Choose ANY country you want your companion to be from + <br>Select an age from 18 to 90 years old!";
        break;
      case "personality":
        showBuyUnlockAll = true;
        showBuyCategory = true;

        name_payment_option = "personality";
        mainTitle = "Personality Trait Unavailable!";
        unlockTitle = "👤 Unlock All Personality Traits! 💭";
        mainText = "Select any personality trait, and select/type any hobby!";
        break;
      case "personality/hobby":
        showBuyCategory = true;
        showBuyUnlockAll = true;

        name_payment_option = "personality";
        mainTitle = "Specified Hobby Unavailable!";
        unlockTitle = "👤 Unlock All Personality Traits! 💭";
        mainText =
          "Select any intelligence level, any of the Big 5 levels, and select/type any hobby!";
        break;
      case "sexual/mood":
        showBuyCategory = true;
        showBuyUnlockAll = true;

        name_payment_option = "sexual";
        mainTitle = "Specified Arousal Mood Unavailable!";
        unlockTitle = "💋 Unlock All Sexy Bits! 🌶️";
        mainText =
          "Turn the heat to 11 by choosing any arousal level and any kink, including new custom ones!";
        break;
      case "sexual/kinks":
        showBuyCategory = true;
        showBuyUnlockAll = true;

        name_payment_option = "sexual";
        mainTitle = "Specified Kink Unavailable!";
        unlockTitle = "💋 Unlock All Sexy Bits! 🌶️";
        mainText =
          "Turn the heat to 11 by choosing any arousal level and any kink, including new custom ones!";
        break;
      case "custom_instructions":
        showBuyCategory = false;
        showBuyUnlockAll = true;

        mainTitle = "Custom Instructions Unavailable!";
    }
    const sharedInfoStore = useSharedInfoStore();
    sharedInfoStore.fetchPrices();

    const options_unlock_this = computed(() => {
      if (!sharedInfoStore.prices) {
        return {
          price: "0.00", // Default value while data is loading
          included_mins: 0, // Default value while data is loading
        };
      }
      const temp_opts =
        sharedInfoStore.prices?.char_creation_options[name_payment_option] ||
        {};

      return {
        price: (temp_opts.price / 100).toFixed(2),
        included_mins: temp_opts.included_mins,
      };
    });
    const all_individual_prices = computed(() => {
      const totalPrice = Object.keys(
        sharedInfoStore.prices?.char_creation_options || {}
      )
        .filter((key) => key !== "unlock_all_options") // Exclude "unlock_all_options"
        .reduce((sum, key) => {
          const price =
            sharedInfoStore.prices.char_creation_options[key]?.price || 0;
          return sum + price;
        }, 0);
      return Math.ceil(totalPrice / 100).toFixed(2);
    });
    const options_unlock_all = computed(() => {
      if (!sharedInfoStore.prices) {
        return {
          price: "0.00", // Default value while data is loading
          included_mins: 0, // Default value while data is loading
        };
      }
      const temp_opts =
        sharedInfoStore.prices?.char_creation_options["unlock_all_options"] ||
        {};

      return {
        price: (temp_opts.price / 100).toFixed(2),
        included_mins: temp_opts.included_mins,
      };
    });
    return {
      options_unlock_all: options_unlock_all,
      options_unlock_this: options_unlock_this,
      unlock_option_name: optionToBuy,
      totIndividualPrices: all_individual_prices,
      mainTitle: mainTitle,
      showBuyCategory: showBuyCategory,
      showBuyUnlockAll: showBuyUnlockAll,
      unlockTitle: unlockTitle,
      mainText: mainText,
    };
  },

  methods: {
    redirectToCheckout,
    // redirectToCheckout(option) {
    //   redirectToCheckout(option);
    // },
    goBack() {
      this.$emit("close");
    },

    emitClose() {
      this.$emit("close");
    },
  },
};
</script>
<style scoped>
/* .list {
  max-width: 400px;
} */
.free-time {
  color: rgb(65 214 65);
}

.title-not-available {
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: red;
  color: white;
  border-radius: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 3px;
  text-align: baseline;
}
.paying-char-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 20px;
  justify-content: stretch;
}
.buymin-btns {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
}

.paying-options {
  display: flex;
  justify-content: space-evenly;
  gap: clamp(15px, 1.5dvw, 20px);
}

.select-and-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 5px;

  margin: 0;
  padding: 0;
}

@media (max-width: 1000px) {
  .paying-options {
    flex-wrap: wrap;
  }
}

.text-big-sub {
  font-size: clamp(1.5rem, 3.5dvw, 1.5rem);
}
.emoji {
  font-size: 1.2em;
  vertical-align: bottom;
  line-height: text-top;
}

.onebtn {
  flex-grow: 1;
}

.sub-container {
  flex: 1;
  padding-top: 20px;
  padding-bottom: 20px;
}

.arrow-container {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}
.text-arrow {
  display: inline-block;
  animation: scaleArrow 2s infinite ease-in-out;
  z-index: 1000;
}

.arrow-1 {
  animation-delay: 0s;
}

.arrow-2 {
  animation-delay: 0.33s;
}

.arrow-3 {
  animation-delay: 0.66s;
}

@keyframes scaleArrow {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.8);
  }
}
</style>
