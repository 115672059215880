// router/index.js
import { createRouter, createWebHistory } from "vue-router";
import LandingPage from "@/components/LandingPage.vue";
import MainApp from "@/components/MainApp.vue";
const routes = [
  { path: "/", component: LandingPage }, // Landing Page
  { path: "/chat", component: MainApp },
];

export const router = createRouter({
  history: createWebHistory(),
  routes,
});
