<template>
    <div class="message">
        <span v-if="sender_type === 'User' || sender_type === 'AI'">
            <span :class="{ 'user-message': sender_type === 'User', 'ai-message': sender_type === 'AI' }"><strong>
                    {{ username }}:</strong>&nbsp;
            </span>
            <span class="msg-content">{{ content }}</span>
        </span>
        <span v-else-if="sender_type === 'System'" class="system-message">
            <span class="msg-content" v-html="content"></span>
        </span>
    </div>
</template>

<script>
export default {
    name: "ChatMessage",
    props: {
        sender_type: {
            type: String,
            required: true
        },
        content: {
            type: String,
            required: true
        },
        username: {
            type: String,
            default: 'Unknown'
        }
    }
}
</script>

<style scoped>
.message {
    padding: 2px;
    max-width: 100%;
    text-align: left;
    display: block;
}

.system-message {
    color: #000000;
    padding: 2px;
    max-width: 100%;
    text-align: left;
    display: block;
}


.user-message {
    color: var(--accent-color);
}

.ai-message {
    color: var(--accent-color2);
}
</style>