<template>
  <div>
    <form-msg @close="showForm = false" v-if="showForm" />

    <div
      class="name"
      @click="
        showForm = !showForm && useForm;
        hideSpeechBubble();
      "
      v-if="domainInfo.name === 'OmegleAI'"
    >
      <img
        src="@/assets/logo_no_outline.png"
        alt="Logo"
        class="logo"
        id="logo"
      />
      <span class="text-name"
        ><span
          class="text-megle text-big"
          :class="{ 'text-disappear-first': shrink }"
          >megle</span
        ><span
          class="text-ai text-big"
          :class="{ 'text-disappear-last': shrink }"
          >AI</span
        ></span
      >
    </div>
    <!-- <div
      class="name"
      v-if="domainInfo.name === 'AI peeps'"
      @click="
        showForm = !showForm && useForm;
        hideSpeechBubble();
      "
    >
      <img
        src="@/assets/logo_no_outline.png"
        alt="Logo"
        class="logo"
        id="logo"
        v-if="showLogoFace"
      />
      <span class="text-name"
        ><span class="text-ai text-big">AI</span
        ><span class="text-peeps text-disappear text-big">peeps</span></span
      >
    </div> -->
    <div
      class="name"
      v-if="domainInfo.name === 'The AI Peeps' || domainInfo.name == 'AI Peeps'"
      @click="
        showForm = !showForm && useForm;
        hideSpeechBubble();
      "
    >
      <img
        src="@/assets/logo_no_outline.png"
        alt="Logo"
        class="logo"
        id="logo"
        v-if="showLogoFace"
      />
      <span class="text-name"
        ><span
          class="text-the text-big"
          :class="{
            'text-disappear-first': shrink,
          }"
          >&nbsp;the</span
        ><span
          class="text-ai text-big"
          :class="{ 'text-disappear-last': shrink }"
          >AI</span
        ><span
          class="text-peeps text-big"
          :class="{
            'text-disappear-second': shrink,
          }"
          >peeps</span
        ></span
      >
    </div>
  </div>
</template>

<script>
import { hideSpeechBubble } from "@/services/userService";
import FormMsg from "./FormMsg.vue";

export default {
  components: {
    FormMsg,
  },
  props: {
    fromOnClick: { type: Boolean, default: true },
    showFace: { type: Boolean, default: true },
    shrink: { type: Boolean, default: true },
  },
  computed: {
    domainInfo() {
      return this.$getDomainSpecificInfo();
    },
  },
  data() {
    return {
      useForm: this.fromOnClick,
      showLogoFace: this.showFace,
      hideSpeechBubble: hideSpeechBubble,

      showForm: false,
    };
  },
};
</script>

<style scoped>
.name {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: baseline;
  transform: scale(1);
  margin-bottom: 10px;
  z-index: 100;
}

.logo {
  height: clamp(2.5rem, 4dvw, 3.5rem);
  transform: translateX(1px) translateY(5px) scaleX(-1);
  filter: drop-shadow(-1px 1px 2px rgba(0, 0, 0, 0.6));
  position: relative;
  transition: transform 0.3s ease, filter 0.3s ease-in-out;
  cursor: pointer;
}

.logo:hover {
  transform: translateX(1px) translateY(5px) scaleX(-1) scale(1.3);
  filter: none;
}

.text-name {
  font-weight: bold;
  filter: drop-shadow(-1px 1px 2px rgb(255, 232, 104));
}

.text-megle {
  margin-left: 2px;
  color: #00bfff;
}

.text-the,
.text-megle {
  background-image: linear-gradient(to right, #0095c6, #00bfff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
.text-peeps {
  margin-left: 2px;
  background-image: linear-gradient(to right, #00bfff, #0095c6);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.text-ai {
  margin-left: 5px;
  /* background-image: radial-gradient(ellipse at center, #ffa726, #ffcc26); */
  -webkit-background-clip: text;
  background-clip: text;
  color: #ffa726;
  text-shadow: #ffcc26 0px 0px 3px;
}

@media (max-width: 500px) {
  .text-disappear-first {
    display: none;
  }
}
@media (max-width: 450px) {
  .text-disappear-second {
    display: none;
  }
}
@media (max-width: 350px) {
  .text-disappear-last {
    display: none;
  }
}
</style>
