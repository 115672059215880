<template>
  <div>
    <div
      class="container"
      :class="rowContainer ? 'container-row' : 'container-col'"
    >
      <div class="title">
        <strong>{{ title }}</strong>
      </div>
      <div style="display: flex; gap: 5px; align-items: center">
        <slot></slot>
        <div class="closed-box base-button" @click="openCarousel">
          {{ boxes[finalSelectionIndex].name }}
        </div>
      </div>
    </div>

    <div
      :class="{ 'standard-screen': isOpened }"
      @wheel="handleScroll"
      @touchstart="handleTouchStart"
      @touchmove="handleTouchMove"
      @touchend="handleTouchEnd"
      @click="closeStandardScreen"
    >
      <div v-if="isOpened" class="wrapper">
        <div class="title-wrapper text-mid-big">
          <strong>{{ title }}</strong>
        </div>
        <!-- Display the Random box in the center -->
        <div
          v-if="isInSpecialMode"
          class="box boxes box-carousel prominent-item"
          @click="selectBox('Random')"
        >
          {{ specialOptionName }}
        </div>
        <div style="margin-top: 20px" v-if="isInSpecialMode">
          <strong> Random Selection Amongst These Options</strong>
        </div>
        <div :class="['boxes', isInSpecialMode ? 'grid' : 'carousel']">
          <div
            v-for="(box, index) in filteredBoxes"
            :key="box.name"
            class="box"
            :class="{
              'box-carousel': !isInSpecialMode,
              'closed-box': isInSpecialMode,
              'invalid-option': !box.is_valid && !isInSpecialMode,
              hide: box.name === hideWhenName && isInSpecialMode,
              'selected-box': tempSelectedBoxIndex == index && !isInSpecialMode,
            }"
            :style="isInSpecialMode ? '' : boxStyles[index]"
            @click="selectBox(box.name)"
          >
            {{ box.name }}
          </div>
        </div>
        <div class="boxes grid-not-selected">
          <div
            v-for="box in notRandomBoxes"
            class="box invalid-option"
            :key="box.name"
            :class="{
              'closed-box': isInSpecialMode,
              hide: box.name === hideWhenName,
            }"
            @click="selectBox(box.name)"
          >
            {{ box.name }}
          </div>
          <div
            v-if="!isInSpecialMode && boxes[tempSelectedBoxIndex].description"
            class="txt-descr text-small"
          >
            {{ boxes[tempSelectedBoxIndex].description }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    specialOptionName: {
      type: String,
      required: false,
      default: "Random",
    },
    rowWise: {
      type: Boolean,
      default: true,
    },
    boxes: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    firstSelectedName: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      rowContainer: this.rowWise,
      isOpened: false,
      hideWhenName: this.specialOptionName,
      tempSelectedBoxIndex: 0,
      finalSelectionIndex: 0,
      touchStartY: 0,
      randomPositions: [],
      isInSpecialMode: false,
      touchDeltaY: 0,
      stickySpecialMode: false,
    };
  },
  computed: {
    // isSpecialBtnSelected() {
    //   return this.boxes[this.tempSelectedBoxIndex].name === this.hideWhenName;
    // },
    filteredBoxes() {
      if (this.isInSpecialMode) {
        return this.boxes.filter((box) => box.is_special_visible); // Only show valid options
      }
      return this.boxes;
    },
    notRandomBoxes() {
      if (this.isInSpecialMode) {
        return this.boxes.filter(
          (box) => !box.is_special_visible && box.name != "REandom"
        ); // Only show valid options
      }
      return [];
    },
    // Precompute styles for all boxes
    boxStyles() {
      return this.filteredBoxes.map((_, index) => {
        const distance = Math.abs(index - this.tempSelectedBoxIndex);
        const scale = Math.max(0.2, Math.exp(-0.2 * distance));
        const opacity = Math.max(0.2, 1 - distance * 0.2);
        const translateY = `${(index - this.tempSelectedBoxIndex) * 40}px`;

        return {
          position: "absolute",
          transform: `translateY(${translateY}) scale(${scale})`,
          opacity,
          zIndex: 10 - distance,
        };
      });
    },
  },
  methods: {
    scatteredBoxStyles(index) {
      // If random positions are not yet set, generate them
      if (this.randomPositions.length < this.filteredBoxes.length) {
        this.randomPositions = this.generateRandomPositions(
          this.filteredBoxes.length
        );
      }

      const { x, y } = this.randomPositions[index];

      return {
        opacity: 0.3,
        transform: `translate(${x}px, ${y}px)`,
        zIndex: 10,
      };
    },
    generateRandomPositions(numBoxes) {
      const positions = [];
      const radius = this.filteredBoxes.length * 10; // Define the radius for the circular area

      for (let i = 0; i < numBoxes; i++) {
        // Generate random angle and radius
        const angle = Math.random() * 2 * Math.PI; // Random angle in radians
        const r = Math.sqrt(Math.random()) * radius; // Random radius, square root for uniform distribution

        // Convert polar coordinates to Cartesian coordinates
        const x = r * Math.cos(angle);
        const y = r * Math.sin(angle);

        positions.push({ x, y });
      }

      return positions;
    },
    selectBox(boxName) {
      // Find the index of the box with the specified name
      const foundIndex = this.boxes.findIndex((box) => box.name === boxName);

      if (foundIndex == this.tempSelectedBoxIndex) {
        if (this.boxes[foundIndex].is_valid) {
          this.closeCarousel();
          this.finalSelectionIndex = foundIndex;
          this.$emit("selected-option", boxName);
        } else {
          this.$emit("invalid-selection", boxName);
        }
      }
      if (foundIndex !== -1) {
        this.tempSelectedBoxIndex = foundIndex;
      }
      this.isInSpecialMode =
        this.boxes[this.tempSelectedBoxIndex].name === this.hideWhenName;
    },
    handleScroll(event) {
      const scrollDirection = event.deltaY;

      if (
        scrollDirection > 0 &&
        this.tempSelectedBoxIndex < this.boxes.length - 1
      ) {
        this.tempSelectedBoxIndex++;
      } else if (scrollDirection < 0 && this.tempSelectedBoxIndex > 0) {
        this.tempSelectedBoxIndex--;
      }
      this.isInSpecialMode =
        this.boxes[this.tempSelectedBoxIndex].name === this.hideWhenName;
    },

    handleTouchStart(event) {
      this.touchStartY = event.touches[0].clientY;
    },

    handleTouchMove(event) {
      const touchCurrentY = event.touches[0].clientY;
      this.touchDeltaY = touchCurrentY - this.touchStartY;

      // Adjust the selectedBox proportionally to touchDeltaY
      const moveThreshold = 50; // Define how sensitive the movement should be
      if (Math.abs(this.touchDeltaY) > moveThreshold) {
        if (this.touchDeltaY > 0 && this.tempSelectedBoxIndex > 0) {
          this.tempSelectedBoxIndex--; // Move up
        } else if (
          this.touchDeltaY < 0 &&
          this.tempSelectedBoxIndex < this.boxes.length - 1
        ) {
          this.tempSelectedBoxIndex++; // Move down
        }
        this.touchStartY = touchCurrentY; // Update starting position after move
        this.isInSpecialMode =
          this.boxes[this.tempSelectedBoxIndex].name === this.hideWhenName;
      }
    },

    handleTouchEnd() {
      this.touchStartY = 0;
      this.touchDeltaY = 0;
    },

    openCarousel() {
      this.isOpened = true;
      this.ignoreClick = true;
      //   document.addEventListener("click", this.closeStandardScreen);
      setTimeout(() => {
        this.ignoreClick = false;
      }, 100);
    },

    closeStandardScreen(event) {
      // Check if the target or one of its parents has the "box" class
      const isBoxElement = event.target.closest(".box");
      if (
        !this.ignoreClick &&
        this.isOpened &&
        !isBoxElement // Prevent closing if the target is inside an element with the "box" class
      ) {
        this.closeCarousel();
      }
      //   this.ignoreClick = false;
    },
    closeCarousel() {
      this.isOpened = false;
      document.removeEventListener("click", this.closeStandardScreen);
    },
  },
  mounted() {
    if (this.firstSelectedName) {
      this.tempSelectedBoxIndex = this.boxes.findIndex(
        (box) => box.name === this.firstSelectedName
      );
      this.finalSelectionIndex = this.tempSelectedBoxIndex;
    }
    this.isInSpecialMode =
      this.boxes[this.tempSelectedBoxIndex].name === this.hideWhenName;
  },
};
</script>

<style scoped>
.standard-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  background: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
  z-index: 99;
}

.title-wrapper {
  position: absolute;
  top: -180px;
  z-index: 1000;
  font-weight: bold;
}
.wrapper {
  width: 80dvh;
  height: 250px;
  position: relative;
  margin: auto;
  overflow: visible;
  transition: overflow 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* .wrapper.centered {
  position: fixed;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
} */

.box-carousel,
.closed-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--accent-color2);
  text-align: center;
  /* width: 100%; */
  padding-top: clamp(0rem, 1.2dvh, 5rem);
  padding-bottom: clamp(0rem, 1.5dvh, 5rem);
  padding-right: clamp(0rem, 2.5dvw, 5rem);
  padding-left: clamp(0rem, 2.5dvw, 5rem);
  background-color: var(--cta-color);
  border: 0px solid transparent;
  border-radius: 20px;
  cursor: pointer;
  color: #000000;
  transition: transform 0.3s, opacity 0.3s;
  white-space: nowrap;
}

.closed-box {
  padding-top: clamp(0rem, 0.5dvh, 5rem);
  padding-bottom: clamp(0rem, 0.5dvh, 5rem);
  padding-right: clamp(0rem, 1dvw, 5rem);
  padding-left: clamp(0rem, 1dvw, 5rem);
  text-align: center;
}

.invalid-option {
  background-color: #ffd18bb3;
}
.prominent-item {
  border: 2px solid black;
  z-index: 1000;
}

.base-button {
  transition: background-color 0.3s ease, transform 0.3s ease, color 0.3s ease,
    box-shadow 0.3s ease, border 0.3s ease;
}
.base-button:hover {
  transform: scale(1.05);
  background-color: var(--interactive-element-color);
}
.carousel {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: center;
  width: 100%;
  align-self: stretch;
}

.container-row {
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.container-col {
  flex-direction: column;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
/* .random-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150px;
  height: 150px;
  background-color: #ffcc00;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
} */
.hide {
  display: none;
}

.grid-not-selected {
  padding: 10px;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.grid {
  border: 2px solid black;
  border-radius: 20px;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.txt-descr {
  position: absolute;
  top: 200px;
  background-color: white;
  z-index: 1000;
  border-radius: 20px;
  border: 2px solid var(--interactive-element-color);
  padding: 10px;
  width: 250px;
}

.title {
  white-space: nowrap;
}

.selected-box {
  border: 2px solid black;
}
</style>
