<template>
  <div>
    <button
      class="base-button main-button"
      :class="({ 'only-icon': onlyIconVisible }, { 'small-button': small })"
      @click="onClick"
      :disabled="isDisabled"
    >
      <i
        v-if="icon && iconPosition === 'left' && !isLoading"
        :class="`fa ${icon}`"
      ></i>
      <div class="spinner" v-show="isLoading"></div>
      <span v-if="!onlyIconVisible" v-show="!isLoading">
        <slot></slot>
      </span>

      <i
        v-if="icon && iconPosition === 'right' && !isLoading"
        :class="`fa ${icon}`"
      ></i>
    </button>
    <panel-message v-if="showConfirmDialog">
      <p style="text-align: center" v-html="confirmMessage"></p>
      <button class="base-button" @click="showConfirmDialog = false">
        <i class="fa-solid fa-arrow-left"></i>
        <span>&nbsp;Back</span>
      </button>
      <button
        class="base-button confirm-btn"
        v-if="action != null"
        @click="confirmAction"
      >
        <div class="spinner" v-show="isLoading">&nbsp;&nbsp;</div>

        <span v-show="!isLoading" v-html="performActionTxt"></span>
      </button>
    </panel-message>
  </div>
</template>

<script>
// import PanelMessage from "./PanelMessage.vue";
import { defineAsyncComponent } from "vue";
export default {
  components: {
    PanelMessage: defineAsyncComponent(() => import("./PanelMessage.vue")),
  },
  template: {
    compilerOptions: {
      isCustomElement: (tag) => tag.includes("panel-message"),
    },
  },
  props: {
    smallButton: { type: Boolean, default: false },
    useConfirmDialog: { type: Boolean, default: false },
    confirmMessage: { type: String, default: "Are you sure?" },
    isDisabled: Boolean,
    // Use actions when you want to use the confirmation dialog. Otherwise @click are fine.
    action: Function,
    performActionText: {
      type: String,
      default: "<i class='fa-solid fa-check'></i> Proceed",
    },
    icon: String,
    iconPosition: {
      type: String,
      default: "right",
      validator: function (value) {
        return ["left", "right"].includes(value);
      },
    },
    whenToShrink: {
      type: Number,
      default: 500,
    },
  },
  data() {
    return {
      small: this.smallButton,
      performActionTxt: this.performActionText,
      windowWidth: window.innerWidth,
      isLoading: false,
      showConfirmDialog: false,
    };
  },
  computed: {
    // Compute visibility based on viewport size (could use window width directly)
    onlyIconVisible() {
      // Example: hide text if viewport width is less than 600px
      return this.icon && this.windowWidth < this.whenToShrink;
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth; // Update window width on resize
    },
    onClick() {
      if (!this.isDisabled) {
        if (this.useConfirmDialog) {
          this.showConfirmDialog = true;
        } else {
          if (this.action) {
            this.confirmAction();
          }
        }
      }
    },
    async confirmAction() {
      if (!this.isLoading) {
        this.isLoading = true;
        await this.action();
        this.isLoading = false;
        this.showConfirmDialog = false;
      }
    },
  },
};
</script>

<style scoped>
.base-button {
  width: 100%;
  padding-top: clamp(0rem, 1.2dvh, 5rem);
  padding-bottom: clamp(0rem, 1.5dvh, 5rem);
  padding-right: clamp(0rem, 2.5dvw, 5rem);
  padding-left: clamp(0rem, 2.5dvw, 5rem);
  background-color: var(--cta-color);
  border: 0px solid transparent;
  border-radius: 20px;
  cursor: pointer;
  color: #000000;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  text-align: center;
  transition: background-color 0.3s ease, transform 0.3s ease, color 0.3s ease,
    box-shadow 0.3s ease, border 0.3s ease;
  font-size: clamp(0.9rem, 1.1dvw, 1.5rem);
}

.small-button {
  padding-top: clamp(0rem, 0.5dvh, 5rem);
  padding-bottom: clamp(0rem, 0.5dvh, 5rem);
  padding-right: clamp(0rem, 1dvw, 5rem);
  padding-left: clamp(0rem, 1dvw, 5rem);
}
.base-button span {
  white-space: nowrap;
}
.base-button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
  pointer-events: none;
}

.base-button:hover {
  transform: scale(1.05);
  background-color: var(--interactive-element-color);
}

.hover-effect {
  transform: scale(1.05);
  background-color: var(--interactive-element-color);
}

.base-button.only-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 10px;
}
</style>
