<template>
  <div class="social-btns">
    <base-button
      class="social-btn discord-btn"
      :icon="'fa-brands fa-discord'"
      @click="openDiscordLink"
      :icon-position="'left'"
      :when-to-shrink="0"
    >
      &nbsp;Discord</base-button
    >
    <base-button
      class="social-btn reddit-btn"
      :icon="'fa-brands fa-reddit'"
      @click="openRedditLink"
      :icon-position="'left'"
      :when-to-shrink="0"
    >
      &nbsp;Reddit</base-button
    >
    <base-button
      class="social-btn twitter-btn"
      :icon="'fa-brands fa-x-twitter'"
      @click="openTwitterLink"
      :icon-position="'left'"
      :when-to-shrink="0"
      >&nbsp;Twitter
    </base-button>
  </div>
</template>
<script>
import BaseButton from "./BaseButton.vue";
export default {
  components: {
    BaseButton,
  },
  methods: {
    openDiscordLink(event) {
      event.stopPropagation();
      window.open(
        "https://discord.gg/6kg5XMYtjS",
        "_blank",
        "noopener,noreferrer"
      );
    },
    openTwitterLink(event) {
      event.stopPropagation();
      window.open("https://x.com/theaipeeps", "_blank", "noopener,noreferrer");
    },
    openRedditLink(event) {
      event.stopPropagation();
      window.open(
        "https://www.reddit.com/r/theAIpeeps/",
        "_blank",
        "noopener,noreferrer"
      );
    },
  },
};
</script>
<style scoped>
.social-btn ::v-deep .base-button {
  /* max-width: 35px; */
  color: white;
  font-weight: bold;
  font-size: clamp(1rem, 1dvw, 1.2rem);
  padding-top: clamp(0rem, 1dvh, 5rem);
  padding-bottom: clamp(0rem, 1dvh, 5rem);
  padding-right: clamp(0rem, 1.2dvw, 5rem);
  padding-left: clamp(0rem, 1.2dvw, 5rem);

  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out,
    color 0.3s ease-in-out, box-shadow 0.3s ease;
}

.discord-btn ::v-deep .base-button {
  background-color: var(--discord-blue);
}

.twitter-btn ::v-deep .base-button {
  background-color: black;
}

.reddit-btn ::v-deep .base-button {
  background-color: #ff4500;
}

.social-btn:hover ::v-deep .base-button,
.social-btn:focus ::v-deep .base-button {
  transform: scale(1.1);
  color: #ffffff;
  text-decoration: none;
}

.discord-btn:hover ::v-deep .base-button,
.discord-btn:focus ::v-deep .base-button {
  background-color: white;
  color: var(--discord-blue);
}

.reddit-btn:hover ::v-deep .base-button,
.reddit-btn:focus ::v-deep .base-button {
  background-color: white;
  color: #ff4500;
}

.twitter-btn:hover ::v-deep .base-button,
.twitter-btn:focus ::v-deep .base-button {
  background-color: white;
  color: black;
}

.discord-href {
  text-decoration: none;
  color: white;
}
.social-btns {
  display: flex;
  align-items: center;
  gap: clamp(2px, 1dvw, 50px);
}
</style>
