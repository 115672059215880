<template>
  <div class="sub-container">
    <div class="sub-cont-title text-big-sub" style="color: blue">
      <strong>🎖️Go Premium!🎖️</strong>
    </div>
    <div>
      <!-- <div class="subscribe-cont"> -->
      <strong>You'll get:</strong>
      <br />
      <div class="list-container">
        <ul style="margin-left: 15px; margin-block-end: 0px">
          <!-- <li style="text-align: left">Early Access to New Features</li> -->
          <li style="text-align: left">Better AI Memory💡</li>
          <li style="text-align: left">More Slots to Save AIs💾</li>
          <!-- TODO: MORE?! How many-->
          <li style="text-align: left">
            Generate Up To
            {{ sharedInfoStore.sharedInfo.default_subscribed_max_new_ai_daily }}
            AIs <strong>daily⚡</strong>
          </li>
        </ul>
      </div>
      <div class="text-mid-big"><strong>+</strong></div>

      <div class="text-mid-big text-unlock">
        <strong
          ><span class="emoji-unlock">🔓</span>
          Unlock Everything
          <span class="emoji-unlock">🔑</span></strong
        >
      </div>
      <div class="text-big"><strong>+</strong></div>

      <div>
        <strong
          class="text-big"
          style="
            text-shadow: #ffcc26 0px 0px 3px;
            color: red;
            white-space: nowrap;
          "
          >🔥Unlimited Chatting Time🔥</strong
        >
      </div>
    </div>
    <base-button
      class="spend onebtn"
      :action="() => redirectToCheckout({ type: 'subscription' })"
    >
      <div class="text-in-sub-btn">
        <div>
          <span class="emoji">👑</span>
          <span class="paid-seconds-left text-mid-big">$9.99</span
          ><span style="vertical-align: bottom">/month</span
          ><span class="emoji">👑</span>
        </div>
        <!-- <div style="margin-top: 5px">=</div> -->
        <div class="infinity-mins text-big">
          <i
            class="fa-solid fa-infinity text-big"
            style="vertical-align: bottom"
          ></i>
          mins
        </div>
      </div>
    </base-button>
    <div class="text-small"><i>Cancel anytime!</i></div>
    <!-- </div> -->
  </div>
</template>
<script>
import BaseButton from "@/components/BaseButton.vue";
import { redirectToCheckout } from "@/services/userService.js";
// import "@/styles/paying_styles.css";
import { useSharedInfoStore } from "@/store/sharedInfoStore";

// import { loadStripe } from "@stripe/stripe-js";
// import { auth } from "@/services/firebase";

export default {
  components: {
    BaseButton,
  },
  data() {
    return {
      sharedInfoStore: useSharedInfoStore(),
    };
  },
  methods: {
    redirectToCheckout,
  },
};
</script>
<style scoped>
ul {
  padding-inline-start: 20px;
  margin-block-start: 0px;
}

.text-in-sub-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.infinity-symbol {
  font-family: "Arial", sans-serif;
  font-style: italic; /* Makes the infinity symbol italic */
  /* font-size: 1.2em; Adjusts the size to make it larger or smaller */
  vertical-align: middle; /* Aligns vertically with surrounding text */
  margin-left: 2px; /* Optional: adds space between the 'x' and the symbol */
}
.infinity-mins {
  font-weight: bold; /* Makes the gradient more visible */
  background: linear-gradient(
    to right,
    #00ddff,
    #01ff6a
  ); /* Example gradient */
  -webkit-background-clip: text; /* Clips the background to the text (WebKit browsers) */
  background-clip: text;
  color: transparent; /* Makes the text transparent, showing the background */
  display: inline-block; /* Ensures the background size covers the text */
}
/* .subscribe-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
} */

.list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.text-big-sub {
  font-size: clamp(1.5rem, 3.6dvw, 1.7rem);
  text-shadow: #ffcc26 0px 0px 3px;
}
.emoji {
  font-size: 1.2em;
  vertical-align: bottom;
  line-height: text-top;
}

/* .onebtn {
  flex-grow: 1;
} */

.sub-container {
  padding-top: 20px;
  padding-bottom: 20px;
}
.text-unlock {
  color: rgb(214 65 194);
  white-space: nowrap;
}
@media (max-width: 500px) {
  .emoji-unlock {
    display: none;
  }
  .text-unlock {
    font-size: clamp(1rem, 1.9dvw, 2.5rem);
    white-space: wrap;
  }
  .text-big {
    font-size: clamp(1rem, 1.9dvw, 2.5rem);
  }
}
</style>
