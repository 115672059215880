<template>
  <div>
    <AppHeader @show-sign-in="showSignInPanel = true" />

    <PreferencePanel
      :close-on-click-out="!introCreation"
      :back-button="!introCreation"
      :can-create="introCreation"
      :intro-panel="introCreation"
      @show-sign-in="showSignInWithMessage($event)"
      @show-time-or-subscribe="showTimeOrSubscribe = true"
      v-if="showPreferences && userStore.authReadyFromServer"
      @close="
        chatAutostart = !introCreation;
        startChat = true;
        showPreferences = false;
        introCreation = false;
        // triggerSpeechMessages();
        userStore.websocket.send(JSON.stringify({ type: 'activate_chat' }));
      "
    />
    <ChatInterface
      :auto-start="chatAutostart"
      @show-preferences="showPreferences = true"
      @show-sign-in="showSignInWithMessage($event)"
      @show-time-or-subscribe="showTimeOrSubscribe = true"
      v-if="startChat"
    />
    <sign-in-panel
      v-if="showSignInPanel"
      @close="showSignInPanel = false"
      :title_message="this.messageSignInPanel"
    />
    <time-or-subscribe
      @close="showTimeOrSubscribe = false"
      v-if="showTimeOrSubscribe"
      :backButton="true"
      ><span style="color: red" class="text-mid-big"
        ><strong>You are out of time!</strong></span
      >
    </time-or-subscribe>
  </div>
</template>

<script>
import { showSpeechBubble, getItemWithDefault } from "@/services/userService";
import AppHeader from "@/components/AppHeader.vue";
import { useUserStore } from "@/store/userStore";
import PreferencePanel from "@/components/PreferencePanel.vue";
import ChatInterface from "@/components/ChatInterface.vue";
import TimeOrSubscribe from "@/components/paying_components/TimeOrSubscribe.vue";
import SignInPanel from "@/components/SignInPanel.vue";
export default {
  name: "MainApp",
  components: {
    ChatInterface,
    PreferencePanel,
    SignInPanel,
    AppHeader,
    TimeOrSubscribe,
  },
  mounted() {
    setTimeout(() => {
      showSpeechBubble(
        "Questions? Feedback? Click here to send us a quick message! 💌",
        "logo",
        6000,
        "top"
      );
    }, 1.5 * 60 * 1000);
  },
  data() {
    const showPreferenceFirstTime =
      getItemWithDefault("show_preference_first_time", true) === true;
    return {
      messageSignInPanel: "",
      chatAutostart: !showPreferenceFirstTime,
      showTimeOrSubscribe: false,
      introCreation: showPreferenceFirstTime,
      showPreferences: showPreferenceFirstTime,
      startChat: !showPreferenceFirstTime,
      userStore: useUserStore(),
      showSignInPanel: false,
    };
  },
  methods: {
    showSignInWithMessage(message = "") {
      this.showSignInPanel = true;
      this.messageSignInPanel = message;
    },
    getItemWithDefault,
  },
};
</script>
