<template>
  <div class="container">
    <p>
      There are <strong>many ways</strong> to get
      <span class="free-seconds-left">free time</span>!
    </p>
    <div class="sub-container">
      <p class="sub-cont-title">&nbsp;Once a day&nbsp;🔂</p>
      <base-button
        class="fb-button"
        :class="{ doNotGetTimeWhenSharedFB: !userStore.getTimeWhenSharedFB }"
        :icon="'fa-brands fa-facebook-f'"
        :when-to-shrink="0"
        @click="openFBshare"
      >
        {{ buttonText }}<i class="fa-solid fa-share">&nbsp;</i>
      </base-button>
      <base-button
        class="twitter-button"
        :class="{ doNotGetTimeWhenSharedX: !userStore.getTimeWhenSharedX }"
        :icon="'fa-brands fa-x-twitter'"
        :when-to-shrink="0"
        @click="openTwitterShare"
      >
        Share On Twitter&nbsp;&nbsp;<i class="fa-solid fa-share">&nbsp;</i>
      </base-button>
      <div v-if="shareTimeMessage != ''" v-html="shareTimeMessage"></div>
    </div>
    <!-- <div class="sub-container">
      <p class="sub-cont-title">&nbsp;As much as you want&nbsp;♾️</p>
      <base-button
        class="video-button"
        :icon="'fa fa-play-circle'"
        :when-to-shrink="0"
        @click="watchVideo"
      >
        Watch a short video&nbsp;&nbsp;
      </base-button>
    </div> -->
    <div class="sub-container">
      <p class="sub-cont-title">&nbsp;Participate&nbsp;⌛</p>
      <base-button
        class="discord-button"
        @click="openDiscordLink"
        :icon-position="'left'"
        :when-to-shrink="0"
      >
        Join us on Discord&nbsp;<i class="fa-brands fa-discord"></i>
      </base-button>
      <div class="list-container">
        You'll get free time for
        <ul class="list">
          <li>reporting <strong>bugs&nbsp;🐛</strong></li>
          <li>giving <strong>suggestions&nbsp;💡</strong></li>
          <li>
            just
            <strong>interacting&nbsp;💬</strong>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
// import { auth } from '@/firebase';
import BaseButton from "./BaseButton.vue";
import { useUserStore } from "@/store/userStore";
import { auth } from "@/services/firebase";

export default {
  components: {
    BaseButton,
  },
  mounted() {
    document.addEventListener("visibilitychange", this.manageMessageVisibility);
  },
  destroyed() {
    document.removeEventListener(
      "visibilitychange",
      this.manageMessageVisibility
    );
    clearTimeout(this.messageTimeout); // Clean up the timer when the component is destroyed
  },
  watch: {
    // Watch for changes in visibility state
    messageVisible(val) {
      if (val) {
        this.manageMessageVisibility();
      }
    },
  },
  data() {
    return {
      buttonText: "Share On Facebook  ",
      shareTimeMessage: "",
      messageVisible: false,
      userStore: useUserStore(),
    };
  },
  methods: {
    openDiscordLink(event) {
      event.stopPropagation();
      window.open(
        "https://discord.gg/6kg5XMYtjS",
        "_blank",
        "noopener,noreferrer"
      );
    },
    async shareAndUpdate(platform) {
      const token = await auth.currentUser.getIdToken(true);
      const response = await fetch(`${process.env.VUE_APP_URL}/api/share`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          platform: platform,
        }),
      });
      if (response.ok) {
        const data = await response.json();
        if (data["result"] == "NOPE") {
          this.shareTimeMessage =
            data["remaining_days"] == 1
              ? `<strong>To get free time, try again tomorrow!</strong>`
              : `<strong>To get free time, try again in ${data["remaining_days"]} days!</strong>`;
        } else {
          this.shareTimeMessage = "<style='color:green'>Free Time Added!";
        }
        this.messageVisible = true; // Mark the message as ready to be displayed
        this.manageMessageVisibility();
      }
    },

    manageMessageVisibility() {
      if (document.visibilityState === "visible" && this.messageVisible) {
        // Only start the timer if the document is visible and the message is supposed to be shown
        this.startMessageTimer();
      }
    },
    startMessageTimer() {
      // Clear any existing timer
      clearTimeout(this.messageTimeout);
      this.messageTimeout = setTimeout(() => {
        this.shareTimeMessage = "";
        this.messageVisible = false; // Reset visibility status after message disappears
      }, 3000);
    },
    async openFBshare(event) {
      const url = this.$getDomainSpecificInfo().url;
      event.stopPropagation();
      window.open(
        `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          url
        )}`,
        "_blank"
      );
      await this.shareAndUpdate("fb");
      this.userStore.userRecord.getTimeWhenSharedFB = false;
    },
    async openTwitterShare(event) {
      event.stopPropagation();

      const { url, name } = this.$getDomainSpecificInfo();
      const via = "@theAIpeeps";
      const text = encodeURIComponent("Check this out! " + via);
      let hashtags = "";
      if (name === "OmegleAI") {
        hashtags = encodeURIComponent(
          "AI,chatbots,aipeeps,ai_peeps,the_ai_peeps,omegle_ai,ai_character"
        );
      } else if (name === "AI peeps") {
        hashtags = encodeURIComponent(
          "AI,chatbots,aipeeps,ai_peeps,the_ai_peepsomegle_ai,ai_character"
        );
      } else if (name === "The AI Peeps") {
        hashtags = encodeURIComponent(
          "AI,chatbots,aipeeps,ai_peeps,the_ai_peeps,omegle_ai,ai_character"
        );
      }

      window.open(
        `https://twitter.com/intent/tweet?url=${encodeURIComponent(
          url
        )}&text=${text}&hashtags=${hashtags}`,
        "_blank"
      );
      await this.shareAndUpdate("x");
      this.userStore.userRecord.getTimeWhenSharedX = false;

      return false;
    },
    emitClose() {
      this.$emit(" close");
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  gap: clamp(2px, 10dvw, 20px);
  align-items: center;
}

.list {
  list-style-type: none;
  padding-left: 10px;
  margin: 5px;
}

.fb-button ::v-deep .base-button {
  position: relative;
  color: white;
  border: none;
  background-image: linear-gradient(to right, #5f9ee7, #1877f2);
  transition: background-color 0.8s ease, color 0.8s ease, transform 0.8s ease,
    box-shadow 0.8s ease, font-weight 1s ease;
  overflow: hidden;
}

.fb-button ::v-deep .base-button::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(to right, #1877f2, #0000ff);
  opacity: 0;
  transition: opacity 0.8s ease;
  z-index: -1;
}

.fb-button ::v-deep .base-button:hover::after {
  opacity: 1;
}

.fb-button.doNotGetTimeWhenSharedFB ::v-deep .base-button {
  background-image: none;
  background-color: #96b4dd;
  color: #808080;
  opacity: 0.6;
}

.fb-button.doNotGetTimeWhenSharedFB ::v-deep .base-button:hover::after {
  opacity: 0;
}

.twitter-button ::v-deep .base-button {
  position: relative;
  color: white;
  border: none;
  background-image: linear-gradient(to right, #555, black);
  transition: background-color 0.8s ease, color 0.8s ease, transform 0.8s ease,
    box-shadow 0.8s ease, font-weight 1s ease;
  overflow: hidden;
}

.twitter-button ::v-deep .base-button::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(to right, black, #1da1f2);
  opacity: 0;
  transition: opacity 0.8s ease;
  z-index: -1;
}

.twitter-button ::v-deep .base-button:hover::after {
  opacity: 1;
}

.twitter-button.doNotGetTimeWhenSharedX ::v-deep .base-button {
  background-image: none;
  background-color: #7e8793;
  color: black;
  opacity: 0.6;
}

.twitter-button.doNotGetTimeWhenSharedX ::v-deep .base-button:hover::after {
  opacity: 0;
}

.video-button ::v-deep .base-button {
  position: relative;
  color: white;
  border: none;
  background-image: linear-gradient(to right, #ff7575, #ff0000);
  transition: background-color 0.8s ease, color 0.8s ease, transform 0.8s ease,
    box-shadow 0.8s ease, font-weight 1s ease;
  overflow: hidden;
}

.video-button ::v-deep .base-button::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(to right, #ff0000, #af0000);
  opacity: 0;
  transition: opacity 0.8s ease;
  z-index: -1;
}

.video-button ::v-deep .base-button:hover::after {
  opacity: 1;
}

.social-btn ::v-deep .base-button {
  position: relative;
  color: white;
  border: none;
  background-image: linear-gradient(to right, #848ce7, #2a3ae6);
  transition: background-color 0.8s ease, color 0.8s ease, transform 0.8s ease,
    box-shadow 0.8s ease, font-weight 1s ease;
  overflow: hidden;
}

.discord-button ::v-deep .base-button {
  position: relative;
  color: white;
  border: none;
  background-image: linear-gradient(to right, #848ce7, #2a3ae6);
  transition: background-color 0.8s ease, color 0.8s ease, transform 0.8s ease,
    box-shadow 0.8s ease, font-weight 1s ease;
  overflow: hidden;
}

.discord-button ::v-deep .base-button::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(to right, #2a3ae6, #0000ca);
  opacity: 0;
  transition: opacity 0.8s ease;
  z-index: -1;
}

.discord-button ::v-deep .base-button:hover::after {
  opacity: 1;
}
</style>
