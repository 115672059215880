// src/stores/sharedInfoStore.js
import { defineStore } from "pinia";

export const useSharedInfoStore = defineStore("sharedInfo", {
  state: () => ({
    sharedInfo: null,
    prices: null,
    loading: false,
    error: null,
  }),
  actions: {
    async fetchSharedInfo() {
      this.loading = true;
      try {
        const response = await fetch(`${process.env.VUE_APP_URL}/shared-info`);

        this.sharedInfo = await response.json();
      } catch (error) {
        this.error = error;
        console.error("Error fetching shared info:", error);
      } finally {
        this.loading = false;
      }
    },
    async fetchPrices() {
      this.loading = true;
      try {
        const response = await fetch(`${process.env.VUE_APP_URL}/prices`);

        this.prices = await response.json();
      } catch (error) {
        this.error = error;
        console.error("Error fetching shared info:", error);
      } finally {
        this.loading = false;
      }
    },
  },
  getters: {
    getSharedValue: (state) => (key) => {
      return state.sharedInfo ? state.sharedInfo[key] : null;
    },
    getPrices: (state) => (key) => {
      return state.prices ? state.prices[key] : null;
    },
  },
});
