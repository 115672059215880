<template>
  <div class="landing-page">
    <WelcomeScreen @close="$router.push('/chat')" />
  </div>
</template>

<script>
import WelcomeScreen from "@/components/WelcomeScreen.vue";

export default {
  name: "LandingPage",
  components: {
    WelcomeScreen,
  },
};
</script>

<style scoped>
.landing-page {
  background-image: url("@/assets/grid_collage_image_13cols_rounded.jpg");
  background-size: 200% auto; /* Make the background twice the width of the viewport */
  background-position: 0 center;
  background-repeat: no-repeat;
  /* width: auto; */
  height: 100dvh;
  animation: backgroundMove 200s linear infinite; /* Adjust speed of the movement */
}

@media (max-width: 1100px) {
  .landing-page {
    background-size: auto 100dvh; /* Make the background twice the width of the viewport */
  }
}
@keyframes backgroundMove {
  0% {
    background-position: 0 center;
  }
  50% {
    background-position: 100% center; /* Move to the right */
  }
  100% {
    background-position: 0 center; /* Move back to the original position */
  }
}
</style>
