import { createApp } from "vue";
import { createPinia } from "pinia";
import VueSocialSharing from "vue-social-sharing";
import App from "./App.vue";
import VueGtag from "vue-gtag";
import { useSharedInfoStore } from "@/store/sharedInfoStore";
import { getDomainSpecificInfo } from "./services/userService";
import { router } from "./router"; // Add router
import "./styles/styles.css";
const app = createApp(App);

app.use(router); // Use router in your app

app.use(VueGtag, {
  config: {
    id: "G-VBSWGSWG8S",
    params: {
      linker: {
        domains: ["omegleai.com", "aipeeps.org", "theaipeeps.com"],
      },
    },
  },
});

const pinia = createPinia();
app.use(VueSocialSharing);
app.use(pinia);
app.config.globalProperties.$getDomainSpecificInfo = getDomainSpecificInfo;
const sharedInfoStore = useSharedInfoStore();
sharedInfoStore.fetchSharedInfo().then(() => {
  app.mount("#app");
});
